<template>
    <Card>
        <template #title> Disclaimer </template>
        <template #content>
            This tool interprets historical data using Machine Learning. The information provided by this tool is for informational purposes only. Any results obtained in connection with the use of this tool are for your internal R&D activities only, and are not to be relied upon by you or any third parties, directly or indirectly, in any way. This site is provided by Chemours on an "as is" and "as available" basis. Chemours makes no representations or warranties of any kind, express or implied, as to the operation of the tool or the information, content, materials, or results obtained by the use of this tool. To the full extent permissible by applicable law, Chemours disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose. You acknowledge, by your use of the tool that your use of the tool is at your sole risk. In no event shall Chemours be liable for any loss, liability or damages of any nature whatsoever resulting from the use of this tool and the information contained herein. Users are advised to experimentally verify any results obtained from the use of the tool.
        </template>
    </Card>
</template>

<script>
export default {};
</script>

<style>
</style>